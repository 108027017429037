const TermsOfUse = () => {
  return (
    <>
      <div className=" terms">
        <h2 className="text-center">
          {" "}
          <u>LEGAL DISCLAIMER </u>
        </h2>
        <h2 className="">Last revised on: April 22nd, 2024 </h2>
        <p>
          The data or information provided herein by GeoFuels LLC. (“we”, “us”,
          or “our”) on this site (the “Site”) is for general use for any user.
          All data on the Site is given in confidence and shall be considered
          confidential, be that as it may, we make no representation or
          guarantee of any kind, express, implied, or inferred, with respect to
          the precision, availability, ampleness, legitimacy, validity,
          dependability, completeness, quality, accessibility, or completeness
          of any data on the Site. Nothing herein shall be considered a legal
          contract or legally binding instrument. We shall not have any
          liability under any circumstances for the information provided herein.
          Use the information herein at your own risk.
        </p>
      </div>
    </>
  );
};

export default TermsOfUse;
